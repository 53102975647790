import { CONFIGURATION_TYPES, DATATABLE_TYPES } from 'state-domains/constants/mxdeposit';
import { Category, FilterOperator, SystemFilter, UserWithType } from 'state-domains/domain';

export interface ConfigurationFilterProps {
    configurationType: CONFIGURATION_TYPES | DATATABLE_TYPES;
    onApply: Function;
}

export interface FilterInfo {
    mainCollection: Record<string, any>;
    categorySelector: any;
    usedInSearchCollection: Record<string, any>;
    realtedCollectionsFilters: Record<string, {
            locationParams: { path: string; type: FilterPathTypes }[];
            filterCollection: any[];
        }>;
}

export enum FilterPathTypes {
    DICT = 'DICT',
    STR = 'STR',
}

export interface FilterDataCollection {
    mainCollection: Record<string, any>;
    categories: Record<string, Category>;
    users: Record<string, UserWithType>;
    usedInSearchCollection: Record<string, any>;
    filterOperators: Record<string, FilterOperator>;
    userId: string;
    systemFilters: Record<string, SystemFilter>;
    realtedCollectionsFilters: Record<string, {
            locationParams: { path: string; type: FilterPathTypes }[];
            filterCollection: any[];
        }>;
}
